import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "links"
    }}>{`Links`}</h1>
    <p>{`For users who want the flexibility to embed tokens as custom links, we allow you to create naked links as tokens.`}</p>
    <p>{`The primary use case is if an organization wants to embed a link into an email that is sent from a company domain. For security reasons, we are unable to send email using your company's domain. However, if you wanted to embed a token in an email that is sent from, for example, the Controller to the CFO, advising him/her of a fake new banking user id and password with a link (the security token) directly to the "bank", you could create a link token.`}</p>
    <p><strong parentName="p">{`WE WANT TO HEAR FROM YOU!!!`}</strong>{` If you've created unique ways to embed tokens, please let us know! We would love to hear how our users are creatively deploying Dragnet, and we might be able to incorporate your methods into Dragnet!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      